// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore, addDoc, collection } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBwWDhkAEc1IiFSHRuFrhJxcVYGTiUXUkw",
    authDomain: "kuofarm-710d3.firebaseapp.com",
    projectId: "kuofarm-710d3",
    storageBucket: "kuofarm-710d3.appspot.com",
    messagingSenderId: "163027608248",
    appId: "1:163027608248:web:d7650cfde4fa8f8d224a4d",
    measurementId: "G-Y8FJJXC32V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const storage = getStorage(app);


const signIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const user = userCredential.user;
        return true
    } catch (error) {
        return { error: error.message }
    }
};

export { signIn, auth, db, storage };


